import { useEffect } from "react";
import { useState } from "react";
import { toast } from 'react-toastify';
import {
    Navigate, useNavigate
} from "react-router-dom";
import { ApiCall } from "../../EnvConfig/env_config";
import { useDispatch } from "react-redux";
import { resetCart } from "../../features/addToCartSlice";
import Loader from "./Loader";
import Thankyou from "./Thankyou";



const VerifyPaymentStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const checkOutData = JSON.parse(localStorage.getItem('checkOut')) || [];
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const [isLoading, setIsLoading] = useState(true)
    const [errDetails, setErrDetails] = useState('');

    const verifyCheckOut = async () => {
        let request = {
            ...checkOutData,
            SessionOrderID: sessionId
        }
        const response = await ApiCall(`/api/Web/createorder`, request);
        if (response.status === "SUCCESS" && response.paymentStatus === 'complete') {
            await setCustomerEmail(response.customerEmailId)
            await setPaymentStatus(response.paymentStatus)
            await localStorage.removeItem('cartProduct')
            await dispatch(resetCart())
            setIsLoading(false)
        } else if (response.status === "ERROR") {
            setErrDetails(response.message)
            setIsLoading(false)
            toast.error(response.message)
            navigate("/")
        }
    }

    useEffect(() => {
        verifyCheckOut()
    }, []);

    if (paymentStatus === 'open') {
        return (
            <Navigate to="/checkout" />
        )
    }

    if (paymentStatus === 'complete') {
        return (

            <Navigate to={`/thankyou?emil_Id=${customerEmail}`} />
            //<>
            //    <section id="success">
            //        <Thankyou customerEmail={customerEmail} />
            //    </section>
            //</>
        )
    }

    if (isLoading) {
        return <Loader />
    }

    return (
        errDetails !== '' ? <p>{errDetails}</p> : null
    )
    
}

export default VerifyPaymentStatus