import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { ApiCall } from "../../EnvConfig/env_config";
import LiveSearchBar from "../Common/LiveSearchBar";
import MiniLoader from "../Common/MiniLoader";
import ModalData from "../Common/Modal";
import { Carousel } from "react-responsive-carousel";
import SEO from "../Common/SEO";


const Plans = () => {
    const [searchBox, setSearchBox] = useState(false)
    const [getPlanDetails, setGetPlanDetails] = useState([])
    const [getOffer, setGetOffer] = useState([])
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate()
    const { Id } = useParams();
    const [planFilterType, setPlanFilterType] = useState([])
    const [planFilterTypeLoader, setPlanFilterTypeLoader] = useState(true)
    const [keyValuePairs, setKeyValuePairs] = useState([]);
    const [detailsModal, setDetailsModal] = useState(false)
    const [openFilterDetails, setOpenFilterDetails] = useState(false)
    const [country, setCountry] = useState('')
    const [planID, setplanID] = useState('')
    const [seoDetails, setSeoDetails] = useState({
        title : '',
        description : '',
    })


    const showDetailsModal = (e, country, planID) => {
        e.preventDefault()
        setCountry(country)
        setplanID(planID)
        setDetailsModal(true)
    }

    const fetchAllResponse = async (Id = '') => {
        let request = {
            "Type": "Plans",
            "NetworkID": '',
            "PackageType": "",
            "Data": "",
            "Validity": "",
            "PriceRange": "",
            "Country": Id,
            "FilterPlan": keyValuePairs ? keyValuePairs : [],
            "Destination": '',
            "PlanID": '',
        }
        try {
            const response = await ApiCall(`/api/Web/GetPlanDetails`, request);
            if (response.status === "SUCCESS") {
                setGetPlanDetails(response.planDetail)
                setSeoDetails((prev) => ({
                    ...prev,
                    title: response.metaTitle,
                    description: response.metaDescription,
                }));
                setLoader(false)
            } else if (response.status === 'ERROR') {
                setGetPlanDetails([])
                setLoader(false)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try after sometimes.');
        }
    }
    const GetOffers = async () => {
        try {
            const response = await ApiCall('/api/Web/GetOffers');
            if (response.status === "SUCCESS") {
                setGetOffer(response.offersDetails)
            } else if (response.status === 'ERROR') {
                setGetOffer([])
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try after sometimes.');
        }
    }
    const GetPlanFilterType = async () => {
        try {
            const response = await ApiCall(`/api/Web/GetPlanFilterType`);
            if (response.status === "SUCCESS") {
                setPlanFilterType(response.filterTypes)
                setPlanFilterTypeLoader(false)
            } else if (response.status === 'ERROR') {
                setPlanFilterType([])
                setPlanFilterTypeLoader(false)
            }
        } catch (error) {
            toast.error('There was a problem with the server, Please try after sometimes.');
        }
    }
    const isActive = (key, value) => {
        return keyValuePairs.some(pair => pair.key === key && pair.value === value);
    };

    const handleNavigate = async (key, value) => {
        const existingPairIndex = keyValuePairs.findIndex(pair => pair.key === key && pair.value === value);

        let updatedPairs;

        if (existingPairIndex !== -1) {
            updatedPairs = keyValuePairs.filter((_, index) => index !== existingPairIndex);
        } else {
            updatedPairs = [...keyValuePairs, { key, value }];
        }
        setKeyValuePairs(updatedPairs);
        const queryString = new URLSearchParams();
        updatedPairs.forEach(pair => {
            queryString.append(pair.key, pair.value);
        });

        navigate(`/plans/${Id}?filter=${queryString.toString()}`);
    }

    useEffect(() => {
        GetPlanFilterType()
        GetOffers()
    }, [])

    useEffect(() => {
        fetchAllResponse(Id)
    }, [Id, keyValuePairs])

    useEffect(() => {
        setKeyValuePairs([])
    }, [Id])

    return (
        <>
            <SEO title={seoDetails.title} description={seoDetails.description} />
            <section className="py-3 py-md-5">
                <div className="container">
                    <div className="destination_hero br-12">
                        <div className="row align-items-center">
                            <div className="col-md-6 col-lg-8 col-6">
                                <div className="hero_cntnt">
                                    <h1>Go Simple </h1>
                                    <h2>Sim Free</h2>
                                    <p className="text-white">Connect anywhere with unlimited data in over 100 destinations</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-6 overflow-hidden">
                                <img src="./assets/images/destination_hero.webp" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center desti_search">
                        <div className="col-md-6">
                            <div className="location_search d-flex align-items-center position-relative">
                                <LiveSearchBar />
                            </div>
                        </div>
                    </div>
                    <div className={`ovarlay_srchbox ${searchBox ? 'd-block' : 'd-none'}`} onClick={() => setSearchBox(false)}></div>
                </div>
            </section>
            <section className="pt-0">
                <div className="container">
                    {
                        !loader && <div className="d-flex justify-content-between mb-2">
                            <span className="fw-bold">
                                {getPlanDetails && getPlanDetails.length > 0
                                    ? `${getPlanDetails.length} Products`
                                    : 'No Product Found'}
                            </span>
                            <span onClick={() => setOpenFilterDetails(!openFilterDetails)} className="open-filter fw-bold pointer">{openFilterDetails ? 'Hide filter' : 'Open filter'}</span>
                        </div>
                    }
                    <div className='row'>
                        <div className="col-md-4 col-lg-3">
                            {
                                !planFilterTypeLoader ?
                                    <div className={`filter_box light-bg br-12 p-3 ${openFilterDetails ? 'show mb-3' : 'hide '}`}>
                                        <div className='d-flex aligin-items-center justify-content-between'>
                                            <h5>Filters</h5>
                                            <h6 >
                                                <Link onClick={() => setKeyValuePairs([])} style={{ textDecoration: "underline" }}>Clear Filter</Link>
                                            </h6>
                                        </div>                                    {
                                            planFilterType?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    <h6 className="mt-3">{item.filterName}</h6>
                                                    <div className="d-flex gap-2 mt-3 pb-3 border-bottom flex-wrap">
                                                        {
                                                            item?.filterValue?.map((subItem, index) =>

                                                                <button
                                                                    onClick={() => handleNavigate(item.filterName, subItem.value)}
                                                                    key={index}
                                                                    className={`mybtn_border ${isActive(item.filterName, subItem.value) ? 'active' : ''}`}

                                                                >{subItem.key}</button>)
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            ))
                                        }
                                    </div> :
                                    <div style={{ minHeight: "100px" }} className="filter_box light-bg br-12 p-3">
                                        <div style={{ position: "relative", marginTop: "30px" }} > <MiniLoader />  </div>
                                    </div>
                            }
                        </div>
                        <div className="col-md-8 col-lg-9">
                            {/*<img src="./assets/images/ads.webp" className="br-12 mb-4" alt="" />*/}
                            {
                                getOffer?.length > 0 &&
                                <div style={{ marginBottom: "20px" }}>
                                    <Carousel
                                        showArrows={true}
                                        autoPlay={true}
                                        infiniteLoop={true}
                                        showThumbs={false}
                                        showStatus={true}
                                        showIndicators={false}
                                        interval={3000}
                                        dynamicHeight={true}
                                    >
                                        {
                                            getOffer?.map((item, index) =>
                                                <div key={index} >
                                                    <img
                                                        src={`data:image/jpg;base64,` + item.images}
                                                        // src={'./assets/images/ads.webp'}
                                                        className="br-12 mb-4"
                                                        style={{ maxHeight: "244px" }}
                                                        alt={item.offerName}
                                                        onError={(e) => { e.target.onerror = null; e.target.src = './assets/images/image_not_available.png'; }}
                                                    />
                                                </div>
                                            )
                                        }
                                    </Carousel>
                                </div>
                            }
                            <div className="choose_destination">
                                {
                                    loader ?
                                        (
                                            <div className="row">
                                                <div style={{ position: "relative" }} className="col-md-12 col-lg-12">
                                                    <MiniLoader />
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            getPlanDetails?.length === 0 ?
                                                (
                                                    <div className="row">
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <img src='./assets/images/productnofound.svg' className='img-fluid' style={{ height: "400px", width: "200px" }} alt="" />

                                                        </div>
                                                        <h5 style={{ textAlign: "center" }}>No Product Found</h5>
                                                    </div>
                                                ) :
                                                (
                                                    <div className="row">
                                                        {
                                                            getPlanDetails.map((planData, index) => (
                                                                <div
                                                                    onClick={(e) => showDetailsModal(e, planData.coverage, planData.planID)}
                                                                    key={index}
                                                                    className="col-lg-4 col-md-6 mb-4 h-100"
                                                                >
                                                                    <Link className="choose_wrapper position-relative">
                                                                        <div className="choose_header d-flex align-items-center justify-content-between">
                                                                            <div>
                                                                                <h6 className="m-0 text-white">{planData?.coverage}</h6>
                                                                                <p className="m-0 text-white">{planData?.planName.substring(0, 20)}</p>
                                                                            </div>
                                                                            <div className="flag_img">
                                                                                {
                                                                                    planData?.countryFlagURL && (
                                                                                        <img
                                                                                            style={{ height: "40px", width: "55px", objectFit: "cover", marginRight: "10px" }}
                                                                                            src={planData?.countryFlagURL}
                                                                                            alt={`${planData.country}`}
                                                                                        />
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <img
                                                                            src={`data:image/jpg;base64,` + planData?.planImage}
                                                                            alt={`CountryImg_${index}`}
                                                                            className="img-fluid w-100"
                                                                            onError={(e) => {
                                                                                e.target.onerror = null;
                                                                                e.target.src = './assets/images/image_not_available.png';
                                                                            }}
                                                                        />
                                                                        <div className="content_choose">
                                                                            <p>Price {planData?.price} </p>
                                                                            <button className="mybtn secondary-bg text-dark">Buy</button>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                        )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            {detailsModal &&
                <ModalData
                    onClose={() => setDetailsModal(false)}
                    detailsModal={detailsModal}
                    country={country}
                    planId={planID}
                />}
        </>
    )
}

export default Plans;



