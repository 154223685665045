import React from "react";
import { Link } from "react-router-dom";

const Thankyou = ({ customerEmail }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const emil_Id = urlParams.get('emil_Id');
  return (
    <>
      <section style={{background: 'linear-gradient(to bottom, #feca321f, transparent)'}}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src="./assets/images/Thankyou.webp" alt="Thank you" />
            </div>
            <div className="col-md-6">
              <h1>
                Thank you for your{" "}
                <span className="primary-text"> purchase!</span>
              </h1>
              <p className="my-4">
              Your eSIM is on its way to transforming your travel experience! We’re thrilled to have you with us.
              </p>
              <h6>What's Next?</h6>
              <ul>
               <li><b>Check Your Inbox:</b> We’ve sent you an <a className="text-primary" href="">{emil_Id}</a> with your eSIM details and setup instructions.</li>
                <li><b>Quick Setup:</b> Follow our easy steps to activate your eSIM in minutes.</li>
                <li><b>24/7 Support:</b> If you need any assistance, our support team is here to help—anytime, anywhere.</li>
              </ul>
              <div>
                <h6>Why Choose Us?</h6>
                <p>With flexible data plans in over 150 countries, you’re always connected with us—no SIM cards, no hidden fees, just seamless global connectivity.</p>
              </div>
              <Link to='/destinations' className="mybtn primary-bg text-white mt-3">
                 <i class="bi bi-shop fs-6"></i>  Back to Store
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
