import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Affliate = () => {
    const navigate = useNavigate(); 

    useEffect(() => {
        const downloadPDF = () => {
            // Static path to your PDF file
            const pdfUrl = './assets/pdf/Simfly-Affliate.pdf'; 

            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'affiliate-file.pdf'; 
            link.click(); 

            // Redirect to the home page after 1 second
            setTimeout(() => {
                navigate('/simfly_new-partner-platform'); 
            }, 1000); 
        };

        downloadPDF();
    }, [navigate]); 

    return 
};

export default Affliate;
