import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AdminApiCall } from '../../../EnvConfig/env_config';
import { adminSessionExpire } from '../../../features/authSlice';
import { setFocus } from '../../Common/Common';
import { AlertBox, ConfirmAlertBox, InputBox } from '../../Common/Input';
import Loader from '../../Common/Loader';
import { NewTable } from '../../Common/NewTable';


const CountryMetaMaster = ({ open, setOpen }) => {
    const [edit, setEdit] = useState(false)
    const [showform, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, settableData] = useState([]);
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const [sortConfig, setSortConfig] = useState({ sortColumn: null, sortOrder: null });
    const [pageSize, setPageSize] = useState("10");
    const [filterData, setFilterData] = useState({
        type: "S",
        SearchText: "",
        searchFieldValue: "",
        startValue: "",
    })
    const [formData, setFormData] = useState({
        country: '',
        metaTitle: '',
        metaDescription: '',
    });
    const handleClear = () => {
        setFormData({
            country: '',
            metaTitle: '',
            metaDescription: '',
        });
        setEdit(false)
        setFocus('txtMetaTitle');
    }
    const dispatch = useDispatch()
    const getUserMasterData = async () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData.SearchFieldValue,
                "ComparisonType": filterData.StartValue,
                "SearchingText": filterData.SearchText,
            }],
            ...sortConfig,
            pageIndex: `${currentPageNo}`
        }
        try {
            setIsLoading(true)
            let res = await AdminApiCall('/api/Master/ShowCountryMetaMaster', { ...request, pageSize: pageSize })
            if (res.Status === 'SUCCESS') {
                settableData(res);
            } else if (res.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: res.Status,
                    message: res.Message
                }))
            }
            else if (res.Status === 'ERROR') {
                settableData([])
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleSorting = (SortColumn) => {
        let SortOrder = 'ASC';

        if (sortConfig && sortConfig.SortColumn === SortColumn && sortConfig.SortOrder === 'ASC') {
            SortOrder = 'DESC';
        }
        setSortConfig({ SortColumn, SortOrder });
    };

    const handleSubmit = async () => {
        let request = {
            ...formData,
            Source: "Admin"
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall(`/api/Master/UpdateCountryMetaMaster`, request);
            if (response.status === 'SUCCESS') {
                AlertBox('SUCCESS', response.message, response.focus)
                setShowForm(false)
                handleClear();
                getUserMasterData();
            } else if (response.Status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            } else if (response.status === 'ERROR') {
                setFocus(response.focus);
                AlertBox('ERROR', response.message)
            }
        } catch (error) {
            AlertBox('ERROR', 'There was a problem with the server, Please try again later')
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleEdit = async (id) => {
        const request = {
            Country: `${id}`,
        }
        try {
            setIsLoading(true)
            const response = await AdminApiCall('/api/Master/EditCountryMetaMaster', { ...request })
            if (response.status === 'SUCCESS') {
                setShowForm(true)
                setEdit(true)
                const { productPreviewUrl, focus, message, status, ...restResponse } = response;
                setFormData(restResponse);
              
            } else if (response.status === 'EXPIRED') {
                dispatch(adminSessionExpire({
                    adminSessionExpires: true,
                    status: response.status,
                    message: response.message
                }))
            }
            else if (response.status === 'ERROR') {
                AlertBox(response.status, response.message, response.focus)
            }
            else if (response.status === 'UNAUTHORIZED') {
                AlertBox(response.status, response.message, response.focus)
            }
        }
        catch (error) {
            AlertBox('ERROR', 'Failed to show the data. Please try again.')
        }
        finally {
            setIsLoading(false)
        }
    }
    const handleDownLoadExcel = () => {
        let request = {
            AdvanceFilter: [{
                "SearchingValue": filterData?.SearchFieldValue,
                "ComparisonType": filterData?.StartValue,
                "SearchingText": filterData?.SearchText,
            }],
            Type: 'E',
            PageIndex: '1',
            ...sortConfig,
            pageIndex: `${currentPageNo}`,
            pageSize: pageSize
        }
      //  handleDownloadExcel('/api/Master/ShowPromotionsMaster', fileName, request)
    }

    const handleChange1 = (event, pagenumber) => {
        setCurrentPageNo(pagenumber)
    }

    useEffect(() => {
        document.title = 'Country Meta Master'
        setFocus("txtMetaTitle")
        getUserMasterData()
    }, [currentPageNo, sortConfig, pageSize])


    return (
        <div style={{ marginTop: "64px" }}>
            <main id="main" className={`main ${open ? '' : 'margin_left'}`}  >
                <section className="p-2 mt-2">
                    <div className='headerbg p-3 d-flex justify-content-between'>
                        <h4 className='primary-text'>{'Country Meta Master'}</h4>
                    </div>
                    {showform ? <>
                        <div className='admin_classmain-card rounded-2 mt-2 headerbg' >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">

                                        <InputBox
                                            label='Country'
                                            id='txtCountry'
                                            maxLength='30'
                                            placeholder="Country"
                                            value={formData.country}
                                            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                                            required
                                            disabled
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtMetaTitle")
                                                };
                                            }}
                                        />
                                        <InputBox
                                            label='Meta Title'
                                            id='txtMetaTitle'
                                            placeholder="Meta Title"
                                            value={formData.metaTitle}
                                            onChange={(e) => setFormData({ ...formData, metaTitle: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    setFocus("txtMetaDescription")
                                                };
                                            }}
                                        />
                                        <InputBox
                                            label='Meta Description'
                                            id='txtMetaDescription'
                                            placeholder="Meta Description"
                                            value={formData.metaDescription}
                                            onChange={(e) => setFormData({ ...formData, metaDescription: e.target.value })}
                                            required
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault()
                                                    setFocus("btn_save")
                                                };
                                            }}
                                        />
                                        
                                        <div className="col-lg-12 mt-4">
                                            <div className="lms-new-button">
                                                <button
                                                    id='btn_save'
                                                    className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleSubmit}>
                                                    <i className="bi bi-check-lg" /> Update
                                                </button>

                                                <button className="admin_mybtn mybtn secondary-bg text-dark me-2" onClick={handleClear}>
                                                    <i className="bi bi-arrow-clockwise" /> Reset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <></>}

                </section>

                <NewTable
                    handleEdit={handleEdit}
                   // handleDelete={handleDelete}
                    PageName={'CountryMetaMaster'}
                    handleFilter={() => {
                        getUserMasterData()
                        setFilterData({
                            ...filterData,
                        })
                    }}
                    filterData={filterData}
                    tableData={tableData}
                    setFilterData={setFilterData}
                    currentPageNo={currentPageNo}
                    handleChange1={handleChange1}
                    handleSorting={handleSorting}
                    sortConfig={sortConfig}
                    exportExcelData={false}
                    handleDownLoadExcel={handleDownLoadExcel}
                />
            </main>
            {isLoading && <Loader />}
        </div>

    )
}

export default CountryMetaMaster






